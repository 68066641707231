import React, { useEffect, useState, useRef } from 'react'
import './RequestCallback.css'
import moment from 'moment';
import ReactTable from '../../components/ReactTable/ReactTable';
import { TIMELINE_CONST } from '../../common/constant/common-constants';
import { AssignHospital, getRequestCallback } from '../../helper/RequestCallbackHelper';
import StatusButton from '../../components/StatusButton/StatusButton';
import StatusModal from '../../components/Modal/StatusModal';
import { REQUEST_STAUS } from './STATUS_ENUM';
import chevron from '../../common/icons/chevron.svg';
import { connect } from 'react-redux';
import { ROLE } from '../../enum/role';

const RequestCallback = (props) => {
  const [dataContainer, setDataContainer] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [selectedRequest, setSelectedRequest] = useState(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    handleRequestCallback();
    // Event listener for Command + K
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleRequestCallback = () => {
    getRequestCallback()
      .then((res) => {
        let data = res.data.machineServiceRequest;
        setDataContainer(data);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const openCMS = (ventilatorUDI) => {
    let email = props.email;
    if (email) {
      AssignHospital({
        ventilatorUDI: ventilatorUDI,
        email: email
      }).then(res=>{
        console.log('res', res);
        if (res === 'Hospital ID updated successfully.'){
          window.open(process.env.REACT_APP_CMS_APP_URL, '_blank');
        }else {
          alert(res);
        }
      })
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const openModal = (request, event) => {
    if (!modalIsOpen){
      const rect = event.currentTarget.getBoundingClientRect();
      setModalPosition({ top: rect.bottom + window.scrollY - 80, left: rect.left + window.scrollX - 250 });
      setSelectedRequest(request);
      setModalIsOpen(true);
    }
    else {
      closeModal()
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedRequest(null);
  };

  const handleSave = (status, remark) => {
    handleRequestCallback();
    closeModal();
  };

  const getTableColumn = () => {
    const columns = [
      {
        title: 'SR ID',
        dataIndex: 'requestId',
        key: 'requestId',
        className: 'machine_serial_number_id_class header_column text-align-left',
        render: (value, row) => (
          <>
          {/* <a target='_blank' href={`/ventilator-alarm?vid=${row.machine_udi_registration.machineUDI}`} rel="noreferrer"> */}
            {row.requestId.slice(0,15)}
          {/* </a> */}
          </>
        )
      },
      {
        title: 'DEVICE SERIAL NO.',
        dataIndex: 'machine_udi_registration',
        key: 'machine_udi_registration',
        className: 'current_version_class header_column',
        render: (value, row) => (
            <div className='clickable' onClick={()=>openCMS(row.machine_udi_registration.machineUDI)}>
              {row.machine_udi_registration.machineUDI?.split('-').filter(x=>x.includes('1G'))}
            </div>
        )
      },
      {
        title: 'Current Version',
        dataIndex: 'currentOsVersion',
        key: 'currentOsVersion',
        className: 'machine_serial_number_id_class header_column',
        render: (value, row) => (
          <>
            OS: {row.currentOsVersion} <br />
            SF: {row.currentSoftwareVersion}
          </>
        )
      },
      {
        title: 'MOBILE NUMBER',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        className: 'update_date_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{value}</span>
          }
        }
      },
      {
        title: 'REQUESTED ON',
        dataIndex: 'createdAt',
        key: 'createdAt',
        className: 'update_time_class header_column',
        render: (value, row) => {
          if (!value) {
            return <span>-</span>
          } else {
            return (
            <>
                <span>
                    {moment(value).format(TIMELINE_CONST.DATE_FORMAT)}
                </span><br />
                <span>
                    {moment(value).format(TIMELINE_CONST.TIME_FORMAT)}
                </span>
            </>
            )
          }
        }
      },
      {
        title: 'ATTENDED ON',
        dataIndex: 'attendedAt',
        key: 'attendedAt',
        className: 'update_time_class header_column',
        render: (value, row) => {
          if (!value) {
            return <span>-</span>
          } else {
            return (
                <>
                    <span>{moment(value).format(TIMELINE_CONST.DATE_FORMAT)}</span><br />
                    <span>{moment(value).format(TIMELINE_CONST.TIME_FORMAT)}</span>
                </>
            )
          }
        }
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        key: 'status',
        id: 'ots_status_id',
        className: 'rc-table-cell ots_status_class header_column',
        render: (value, row) => (
            <div onClick={(event) => value === "pending" ? openModal(row, event) : {}}>
              <div class="tooltip">
                <div className='flex-center'>
                  <StatusButton value={REQUEST_STAUS[value.toUpperCase()]} bg={value === "pending" ? "#FFD6D6" : "#DFF6E4"} color={value === "pending" ? "#FF0D0D" : "#21A366"} />
                  {value === "pending" && <img alt="arrow" src={chevron} className={selectedRequest === row ? 'active-chevron' : 'inactive-chevron'} /> }
                </div>
                <span class="tooltiptext">Remark: {row.remark ? row.remark : 'No Remarks'}</span>
              </div>
            </div>
        )
      },
    ];

    return columns;
  };

  const getTableData = () => {
    let filteredData = [];
    if (Array.isArray(dataContainer)) {
      filteredData = dataContainer.filter(item =>
        item.requestId?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
    }
    return filteredData;
  };

  if (!(props.userRole === ROLE['ADMIN'] || props.userRole === ROLE['SERVICE'])) {
		return (
			  <div>
            <h1>Unauthorized Access</h1>
            <p>You do not have the necessary permissions to access this page.</p>
        </div>
		)
	}

  return (
    <div style={{position:"relative"}}>
      <div className='ots-details-container'>
        <div className='system-detail-sub-header-container'>
          <div className='system-detail-sub-header'>
            <div className='system-detail-sub-header-heading'>
              Machine Service Requests
            </div>
            <input
              type='text'
              placeholder='Quick Search         ⌘ K'
              value={searchQuery}
              onChange={handleSearchChange}
              className='search-input'
              ref={searchInputRef}
            />
          </div>
        </div>
        <ReactTable columns={getTableColumn()} data={getTableData()} rowKey="mahcineUDI" className="react-table" />
        {modalIsOpen && (
          <StatusModal
            id={selectedRequest.id}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            onSave={handleSave}
            currentStatus={selectedRequest ? selectedRequest.status : 'pending'}
            position={modalPosition}
          />
        )}
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  const email = state.userDetails?.email
  const userRole = state.userDetails.userRole
  return {
    email: email,
    userRole: userRole,
  };
}

export default connect(mapStateToProps, null)(RequestCallback);
