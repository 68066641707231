import { GetCountryList } from "../adapters/HospitalDashboard/GetCountryList";

// Fetch country list using the adapter
export const fetchCountryList = async () => {
  try {
    const response = await GetCountryList();
    if (response && response.data) {
      console.log("Fetched Country List:", response.data.data);
      return response.data.data; // Array of countries with states
    }
  } catch (error) {
    console.error("Error fetching countries:", error);
    throw error;
  }
};
