import axios from "axios";

export const addWard = async (data) => {
  

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_CMS_APP_URL}/cms/api/deviceApi/addWard`,
        headers: { 
            'authorization': process.env.REACT_APP_AUTHORIZATION
          },
          data: data // Send the data object directly
        
    };

    console.log(config);  


    return axios.request(config);
}
