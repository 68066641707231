import axios from 'axios';
import { GetHospitals } from '../adapters/HospitalDashboard/GetHospital';
import { AddHospital } from '../adapters/HospitalDashboard/AddHospital';

// Function to fetch the hospital list from the API
export const fetchHospitalList = async () => {
  var promise = new Promise(function (resolve, reject) {
    GetHospitals().then((res) => {
        if (res && res.data && res.status === 200) {
            let response = res.data;
            resolve(response);
        } else {
            if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
            else reject("error occured");
        }
    }).catch((err) => {
        console.log("error occured in order details Api :: " + err);
    });
})

return promise;
};

// Function to add a new hospital to the API
export const addHospital = (data) => {
  return new Promise((resolve, reject) => {
    AddHospital(data)
      .then((res) => {
        // Check for successful response (including 201 status)
        if (res && res.data && (res.status === 200 || res.status === 201)) {
          resolve(res.data); // Resolve with the successful response data
        } else {
          // Log the full error response for debugging
          console.error("API Response Error:", res);
          reject(res?.data?.error?.message || "Unexpected error occurred"); // Reject with the error message
        }
      })
      .catch((err) => {
        console.error("API Error:", err); // Log the error if the API call fails
        reject("Unexpected error occurred while adding the hospital."); // Reject with a generic error message
      });
  });
};

  
