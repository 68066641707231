import React, { useEffect, useState } from 'react'
import './SystemVersionDetails.css'
import SystemDetailsSubHeader from '../../components/SystemDetailsSubHeader/SystemDetailsSubHeader'
import { redirect, useNavigate } from 'react-router-dom';
import { PAGE_CONST, TIMELINE_CONST } from '../../common/constant/common-constants';
import ReactTable from '../../components/ReactTable/ReactTable';
import { getSystemVersionDetails } from '../../helper/SystemVersionDetailsHelper';
import StatusButton from '../../components/StatusButton/StatusButton';
import { setInactiveSystemVersionButton } from '../../modules/actions/action';
import { connect } from 'react-redux';
import moment from 'moment';
import MessageNotification from '../../components/MessageNotification/MessageNotification';
import { useLocation } from 'react-router-dom';

const SystemVersionDetails = (props) => {
  const navigate = useNavigate();
  

  const [dataContainer, setDataContainer] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState('Prod');
 
  const handleEnvChange = (env) => {
    setSelectedEnv(env); // Update the selected environment
  };

  const handleHeaderCheckboxChange = () => {
    const allKeys = dataContainer.prod.map((row) => row.uploadDate);
    console.log(allKeys);
    setSelectedRows(isHeaderCheckboxSelected ? [] : allKeys);
    setIsHeaderCheckboxSelected(!isHeaderCheckboxSelected);
  };

  const handleRowCheckboxChange = (rowKey) => {
    const updatedSelectedRows = selectedRows.includes(rowKey)
      ? selectedRows.filter((key) => key !== rowKey)
      : [...selectedRows, rowKey];
    setSelectedRows(updatedSelectedRows);
  };

  useEffect(() => {
    handleSystemVersionDataGet();
  }, []);

  useEffect(() => {
    if (selectedRows.length || isHeaderCheckboxSelected.length) {
      props.enableSystemVersionInactiveButton();
    } else {
      props.disableSystemVersionInactiveButton();
    }
  }, [selectedRows, isHeaderCheckboxSelected]);

  const handleSystemVersionDataGet = () => {
    getSystemVersionDetails()
      .then((res) => {
        let data = res.data;
        console.log(data)
        setDataContainer(data);
      })
      .catch((err) => {
        console.log(err);
      })
  };



  const handleRowSelection = (rowKey) => {
    if (selectedRows.includes(rowKey)) {
      setSelectedRows(selectedRows.filter((key) => key !== rowKey));
    } else {
      setSelectedRows([...selectedRows, rowKey]);
    }
  };

  const handleRowClick = (rowKey, index, event) => {
    const cellToIgnore = 'checkbox';
    if (event.target && event.target.type === 'checkbox') {
      // Ignore the event for checkbox cell 
      return;
    }
    // console.log('Row clicked', rowKey.machine_sn);
    //  Handle the row click event for other cells
    redirect(navigate, PAGE_CONST.MACHINE_DETAILS, rowKey);
    // navigate(`/ota-details/machine-details/${rowKey.machine_sn}`);
  };
  const getTableColumn = () => {
    const columns = [
      // {
      //   title: <input type="checkbox" checked={isHeaderCheckboxSelected} onChange={() => handleHeaderCheckboxChange()} />,
      //   dataIndex: 'checkbox',
      //   key: 'checkbox',
      //   className: 'sticky_column checkbox_class',
      //   children: [
      //     {
      //       title: ' ',
      //       className: 'sticky_column',
      //       render: (value, row) => (
      //         <input
      //           type="checkbox"
      //           checked={selectedRows.includes(row.uploadDate)}
      //           onChange={() => handleRowCheckboxChange(row.uploadDate)}
      //           className="sticky_column checkbox_class"
      //         />
      //       )
      //     }
      //   ],


      // },
      {
        title: 'SYSTEM VERSION',
        dataIndex: 'softwareVersion',
        key: 'softwareVersion',
        className: 'sticky_column system_version_class fixed_column header_column',
        children: [
          {
            title: ' ',
            dataIndex: 'softwareVersion',
            key: 'softwareVersion',
            className: 'sticky_column fixed_column header_column'
          }
        ]
      },
      {
        title: 'OS VERSION',
        dataIndex: 'osVersion',
        key: 'osVersion',
        className: 'sticky_column system_version_class fixed_column header_column',
        children: [
          {
            title: ' ',
            dataIndex: 'osVersion',
            key: 'osVersion',
            className: 'sticky_column fixed_column header_column'
          }
        ]
      },
      {
        title: 'DATE',
        dataIndex: 'uploadDate',
        key: 'uploadDate',
        className: 'sticky_column update_date_class fixed_column header_column',
        children: [
          {
            title: ' ',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            className: 'sticky_column fixed_column header_column',
            render: (value, row) => {
              if (value === null) {
                return '-'
              } else {
                return moment(value).format(TIMELINE_CONST.DATE_FORMAT)
              }
            }
          }
        ]
      },
      {
        title: 'SOFTWARE VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'fixed_column header_column',
        children: [
          {
            title: 'UI',
            dataIndex: 'hmiUiVersion',
            key: 'hmiUiVersion',
            className: 'fixed_column header_column',
          }, {
            title: 'BACKEND',
            dataIndex: 'hmiBackendVersion',
            key: 'hmiBackendVersion',
            className: 'fixed_column header_column',
          }
        ]
      },
      {
        title: 'FIRMWARE VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'fixed_column header_column',
        children: [
          {
            title: 'MASTER',
            dataIndex: 'fMasterVersion',
            key: 'fMasterVersion',
            className: 'fixed_column header_column',
          }, {
            title: 'SLAVE',
            dataIndex: 'fSlaveVersion',
            key: 'fSlaveVersion',
            className: 'fixed_column header_column',

          }
        ]
      },
      {
        title: 'STMOS VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'fixed_column header_column',
        children: [
          {
            title: 'PY APP',
            dataIndex: 'stmPyAppVersion',
            key: 'stmPyAppVersion',
            className: 'fixed_column header_column',

          }, {
            title: 'CAPP',
            dataIndex: 'stmCAppVersion',
            key: 'stmCAppVersion',
            className: 'fixed_column header_column',
          }, {
            title: 'OS',
            dataIndex: 'stmOsVersion',
            key: 'stmOsVersion',
            className: 'fixed_column header_column',
          }
        ]
      },
      {
        title: 'IOMT VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'fixed_column header_column',
        children: [
          {
            title: 'PY APP',
            dataIndex: 'iomtPyAppVersion',
            key: 'iomtPyAppVersion',
            className: 'fixed_column header_column',
          }, {
            title: 'JAPP',
            dataIndex: 'iomtJAppVersion',
            key: 'iomtJAppVersion',
            className: 'fixed_column header_column',
          }, {
            title: 'OS',
            dataIndex: 'iomtOsVersion',
            key: 'iomtOsVersion',
            className: 'fixed_column header_column',
          }
        ]
      },
      {
        title: 'STATUS',
        dataIndex: 'activeStatus',
        key: 'activeStatus',
        id: 'ots_status_id',
        className: 'sticky_column fixed_column header_column',
        children: [
          {
            title: ' ',
            dataIndex: 'activeStatus',
            key: '',
            className: 'sticky_column fixed_column ots_status_class',
            id: 'ots_status_id',
            render: (value, row) => {
              if (value === true) {
                return (
                  <div>
                    <StatusButton value="Active" bg="#DFF6E4" color="#21A366" />
                  </div>
                )
              } else {
                return (
                  <div>
                    <StatusButton value="Inactive" bg="#FFD6D6" color="#FF0D0D" />
                  </div>
                )
              }
            }
          }
        ]
      },
    ];

    return columns;
  };

  const getTableDataProd = () => {
    let data;
    if (Array.isArray(dataContainer.prod)) {
      data = dataContainer.prod;
    } else {
      data = [];
    }

    
    const filteredData = data;
    console.log("FIle Data",filteredData);
    return filteredData;
  }
  
  const getTableDataTest = () => {
    let data;
    if (Array.isArray(dataContainer.test)) {
      data = dataContainer.test;
    } else {
      data = [];
    }

    
    const filteredData = data;
    console.log("FIle Data",filteredData);
    return filteredData;
  }

  return (
    <div className='system-version-details'>
      {props.showUpdateSystemVersionNotification && <div style={{ position: "absolute", left: "0%", top: "11.6%", width: '96%', backdropFilter: "blur(20px)", marginLeft: "2%" }}>
        <MessageNotification systemVersionUpload={true}/>
      </div>}
        {console.log(props.showUpdateSystemVersionNotification, "Notification")}
      <div className='system-details-sub-header'>
        <SystemDetailsSubHeader />
      </div>
      {/* Toggle Buttons */}
      <div className="env-toggle-buttons" style={{ display: "flex", margin: "20px 0", gap: "20px" }}>
        <button
          onClick={() => handleEnvChange('Prod')}
          style={{
            background: "none",
            border: "none",
            color: selectedEnv === 'Prod' ? "#6D1DF0" : "gray",
            borderBottom: selectedEnv === 'Prod' ? "2px solid #6D1DF0" : "none",
            marginRight: "10px",
            cursor: "pointer",
            fontSize: "18px", // Increase text size
            fontWeight: "bold", // Make text bold
            padding: "5px 10px", // Add some padding for a better feel
          }}
        >
          Prod
        </button>
        <button
          onClick={() => handleEnvChange('Test')}
          style={{
            background: "none",
            border: "none",
            color: selectedEnv === 'Test' ? "#6D1DF0" : "gray",
            borderBottom: selectedEnv === 'Test' ? "2px solid #6D1DF0" : "none",
            cursor: "pointer",
            fontSize: "18px", // Increase text size
            fontWeight: "bold", // Make text bold
            padding: "5px 10px", // Add some padding for a better feel
          }}
        >
          Dev
        </button>
      </div>
      <div className='system-update-details-table'>
        <ReactTable columns={getTableColumn()} 
        // data={getTableData()} 
        data={selectedEnv === 'Prod' ? getTableDataProd() : getTableDataTest()} // Toggle data based on environment
        rowKey="systemVersion" 
        className='react-table' />
      </div>
    </div>
  )
};


const mapStateToProps = state => {
  return {
    updateSystemIcon: state.updateSystemIcon.activeUpdateSystemButton, 
    showUpdateSystemVersionNotification: state.showSystemVersionUploadNotification.showUploadSystemVersionNotification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    enableSystemVersionInactiveButton: () => { dispatch(setInactiveSystemVersionButton(true)) },
    disableSystemVersionInactiveButton: () => { dispatch(setInactiveSystemVersionButton(false)) },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemVersionDetails);