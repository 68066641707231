import axios from "axios";

export const GetHospitalWards = async (hospitalId) => {
    let config = {
        method: 'post',
        url: `${process.env.REACT_APP_CMS_APP_URL}/cms/api/deviceApi/getHospitalWards`,
        headers: { 
            'authorization': process.env.REACT_APP_AUTHORIZATION
        },
        data: {
            "hospitalId": hospitalId
        }
    };

    console.log(config); 
     
    

    return axios.request(config);
}
