import axios from "axios";

export const GetBedMachines = async (bedId) => {
    let config = {
        method: 'post',
        url: `${process.env.REACT_APP_CMS_APP_URL}/cms/api/deviceApi/getBedMachines`,
        headers: { 
            'authorization': process.env.REACT_APP_AUTHORIZATION
        },
        data: {
            "bedId": bedId       }
    };

    console.log(config); 
     
    

    return axios.request(config);
}
