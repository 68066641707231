import axios from "axios";

export const AddHospital = async (data) => {
  

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_CMS_APP_URL}/cms/api/deviceApi/addHospital`,
        headers: { 
            'authorization': '6bd610eb914a375eb71fcd0ac4650f8c86601f5b6ad9791cdccdfc517f961e96'
        },
        data: data
    };

    console.log(config);  // Check if headers are set correctly

    return axios.request(config);
}
