import React, { useState, useEffect } from 'react';
import ReactTable from '../../components/ReactTable/ReactTable';
import AddHospitalModal from './AddHospitalModal.jsx';
import { fetchHospitalList } from '../../helper/HospitalHelper.js';
import { addHospital } from '../../helper/HospitalHelper.js';
import { useNavigate } from 'react-router-dom';
import './HospitalDashboard.css';

const HospitalDashboard = () => {
  console.log("mounting HospitalDashboard");
  const navigate = useNavigate(); // Hook for navigation

  const [hospitals, setHospitals] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [statesInCountry, setStatesInCountry] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [countriesWithStates, setCountriesWithStates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    fetchHospitalList()
      .then((res) => setHospitals(res.data))
      .catch((err) => console.error(err));

    fetch('https://countriesnow.space/api/v0.1/countries/states')
      .then((res) => res.json())
      .then((data) => {
        setCountriesWithStates(data.data);
        console.log(data.data);
      })
      .catch((err) => console.error('Error fetching countries and states:', err));
  }, [showSuccessPopup]);

  const handleCountryChange = (e) => {
    const selected = e.target.value;
    setSelectedCountry(selected);
    setSelectedState('');

    const states = countriesWithStates.find((country) => country.name === selected)?.states;
    setStatesInCountry(states || []);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleAddHospital = (newHospital) => {    
  console.log("Adding hospital:", newHospital);

    addHospital(newHospital)
      .then((response) => {
        console.log("Hospital added successfully:", response);
        setHospitals([...hospitals, newHospital]);
        setIsModalOpen(false);
        setShowSuccessPopup(true);

        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Error adding hospital:", err);
        alert(err);
      });
  };

  const filteredHospitals = hospitals.filter((hospital) => {
    const matchesSearch = hospital?.name?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCountry = !selectedCountry || hospital?.country === selectedCountry;
    const matchesState = !selectedState || hospital?.state === selectedState;
    return matchesSearch && matchesCountry && matchesState;
  });

  const handleRowClick = (record) => {
    console.log("Navigating to:", `/hospital-details/wards?hospitalId=${record.id}`);
    navigate(`/hospital-details/wards?hospitalId=${record.id}`);
  };
  
  
  const getColumns = () => [
    { title: 'Hospital Name', dataIndex: 'name', key: 'name' },
    { title: 'Hospital Address', dataIndex: 'address', key: 'address' },
    { title: 'City', dataIndex: 'city', key: 'city' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'Country', dataIndex: 'country', key: 'country' },
    { title: 'Pincode', dataIndex: 'pincode', key: 'pincode' },
  ];

  return (
    <div className="hospital-dashboard">
      <div className="hospital-title">
        <label>Hospitals</label>
      </div>

      <div className="filters-and-actions">
        <div className="filters">
          <div className="filters-title">Filters</div>
          <div className="input-group">
            <select value={selectedCountry} onChange={handleCountryChange}>
              <option value="">Country</option>
              {countriesWithStates.map((country, index) => (
                <option key={`${country.name}-${index}`} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <select value={selectedState} onChange={handleStateChange} disabled={!selectedCountry}>
              <option value="">State</option>
              {statesInCountry.map((state, index) => (
                <option key={`${state.name}-${index}`} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {showSuccessPopup && (
          <div className="success-popup">
            <span>✅ Hospital added Successfully!</span>
          </div>
        )}

        <button className="add-hospital-btn" onClick={() => setIsModalOpen(true)}>
          Add Hospital
        </button>
      </div>

      <ReactTable
        columns={getColumns()}
        data={filteredHospitals}
        rowKey="id"
        config={(record) => ({
          onClick: () => handleRowClick(record),
          style: { cursor: 'pointer' }, // Optional: Add a pointer cursor for better UX
        })}
        className="react-table"
      />
      {isModalOpen && (
        <AddHospitalModal
          onClose={() => setIsModalOpen(false)}
          onSave={handleAddHospital}
          countriesWithStates={countriesWithStates}
        />
      )}
    </div>
  );
};

export default HospitalDashboard;
