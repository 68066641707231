import React, { useState, useEffect } from 'react';
import { fetchCountryList } from '../../helper/LocationHelper.js';
import { AddHospital } from '../../adapters/HospitalDashboard/AddHospital.js';

const AddHospitalModal = ({ onClose, onSave }) => {
  const [hospitalName, setHospitalName] = useState('');
  const [address, setHospitalAddress] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const countryList = await fetchCountryList();
        setCountries(countryList);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    loadCountries();
  }, []);

  const handleCountryChange = (e) => {
    const selected = e.target.value;
    setSelectedCountry(selected);
    setSelectedState('');
    const statesList = countries.find((c) => c.name === selected)?.states || [];
    const uniqueStates = Array.from(
      new Map(statesList.map((state) => [state.name, state]))
    ).map(([_, state]) => state);

    setStates(uniqueStates);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const validate = () => {
    const newErrors = {};
    const alphabetRegex = /^[A-Za-z\s]+$/;
    const numberRegex = /^[0-9]+$/;

    if (!alphabetRegex.test(hospitalName) || hospitalName.length < 3) {
      newErrors.hospitalName = 'Hospital name must be at least 3 characters long and contain only letters.';
    }

    if (!alphabetRegex.test(address)) {
      newErrors.address = 'Incorrect hospital address. Please try again.';
    }

    if (!alphabetRegex.test(city)) {
      newErrors.city = 'Incorrect city name. Please try again.';
    }

    if (!numberRegex.test(pincode)) {
      newErrors.pincode = 'Incorrect pincode. Please try again.';
    }

    if (!selectedCountry) {
      newErrors.country = 'Please select Country.';
    }
  
    if (!selectedState) {
      newErrors.state = 'Please select State.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;  
  };

  const handleSave = () => {
    if (validate()) {
      const newHospital = {
        hospitalName,
        address,
        country: selectedCountry,
        state: selectedState,
        city,
        pincode,
      };

      console.log("Sending data to API:", newHospital);

      AddHospital(newHospital)
        .then((response) => {
          console.log('Hospital added successfully:', response.data);
          onSave(newHospital);  
          onClose();  
        })
        .catch((error) => {
          console.error('Error adding hospital:', error);
          alert('Failed to add hospital. Please try again.');
        });
    } else {
      console.log('Validation failed:', errors);
    }
  };

  return (
    <div className="modal">
      <div className="backdrop" onClick={onClose}></div>
      <div className="modal-content">
        <h2>Add New Hospital</h2>

        {/* Hospital Name */}
        <div className="input-group">
          <input
            type="text"
            value={hospitalName}
            onChange={(e) => setHospitalName(e.target.value)}
            placeholder="Hospital Name"
          />
          {errors.hospitalName && <p className="error">{errors.hospitalName}</p>}
        </div>

        {/* Hospital Address */}
        <div className="input-group">
          <input
            type="text"
            value={address}
            onChange={(e) => setHospitalAddress(e.target.value)}
            placeholder="Hospital Address"
          />
          {errors.address && <p className="error">{errors.address}</p>}
        </div>

        {/* Country Dropdown */}
        <div className="input-group">
          <select value={selectedCountry} onChange={handleCountryChange}>
            <option value="" disabled>
              Country
            </option>
            {countries.map((country, index) => (
              <option key={country.name + '-' + index} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.country && <p className="error">{errors.country}</p>}
        </div>

        {/* State Dropdown */}
        <div className="input-group">
          <select
            value={selectedState}
            onChange={handleStateChange}
            disabled={!selectedCountry}
          >
            <option value="" disabled>
              State
            </option>
            {states.map((state, index) => (
              <option key={state.name + '-' + index} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
          {errors.state && <p className="error">{errors.state}</p>}
        </div>

        {/* City */}
        <div className="input-group">
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="City"
          />
          {errors.city && <p className="error">{errors.city}</p>}
        </div>

        {/* Pincode */}
        <div className="input-group">
          <input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            placeholder="Pincode"
          />
          {errors.pincode && <p className="error">{errors.pincode}</p>}
        </div>

        {/* Actions */}
        <div className="actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default AddHospitalModal;
